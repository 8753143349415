export default [
  {
    path: '/',
    text: 'home'
  },
  {
    path: '/courses',
    text: 'courses'
  },
  {
    path: '/blog',
    text: 'blog'
  }
]
